import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import IconComponent from '@/components/IconComponent.vue';

Vue.use(Vuetify);

export const options = {
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#005EB8',
        primarybkg: '#00386f',
        secondary: '#030027',
        accent: '#5E5B52',
        error: '#F44336',
        success: '#84BD00',
        info: '#5E5B52',
        warning: '#F95738',
        darkgray: '#212121',
        mediumgray: '#757575',
        lightgray: '#E6E6E6',
        graybkg: '#f5f5f5',
        white: '#FFFFFF',
        lightblue: '#ebf5ff',
        contact1: '#ffcb7f',
        contact2: '#8fcaf8',
        contact3: '#a5d6a7',
        contact4: '#f48cb1',
        contact5: '#ffb400',
        contact6: '#c1dd7f',
        contact7: '#ff6666',
        contact8: '#3399ff',
        contact9: '#66ffcc',
        contact10: '9999ff',
      },
      dark: {
        primary: '#84BD00',
      },
    },
  },
  icons: {
    values: {
      'capacity-icon': {
        component: IconComponent,
        props: {
          name: 'capacity-icon',
        },
      },
    },
  },
};

export default new Vuetify(options);
