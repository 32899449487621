
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'IconComponent',
  props: {
    name: {
      type: String as PropType<string>,
      default: null,
    },
  },
  computed: {
    // allows us to change the svg fill color to white, default is primary
    iconColor(): string {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'icon-white';
      }
      return '';
    },
  },
});
