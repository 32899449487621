import { isNullOrEmpty } from '@/utils';
import { logAcxessException, logType } from './logger';

let swRegistration:ServiceWorkerRegistration | null = null;

export function showNotification(title:string, body?:string, attachmentUrl?:string):void {
  if (swRegistration == null || (isNullOrEmpty(body) && isNullOrEmpty(attachmentUrl))) {
    return;
  }
  swRegistration.showNotification(title, {
    body,
    image: attachmentUrl,
    icon: '/img/icons/textel_bubble_mark_152.png',
  });
}

function trackInstalling(worker: ServiceWorker) {
  worker.addEventListener('statechange', () => {
    if (worker.state === 'installed') {
      logAcxessException(logType.Info, 'service worker update found and installed.');
      worker.postMessage({ action: 'skipWaiting' });
    }
  });
}

export async function registerServiceWorker():Promise<void> {
  swRegistration = await navigator.serviceWorker.register('service-worker.js');

  // listen for new installing workers arriving.
  // If one arrives, track its progress.
  swRegistration.addEventListener('updatefound', () => {
    if (swRegistration?.installing) {
      trackInstalling(swRegistration!.installing);
    }
  });
}
